<div class="row">
  <div class="trash-area" (click)="onRemove(officer.value.id)">
    <span class="trash-area__trash" [class.trash-area__trash--disabled]="!isRemovable"
      >&nbsp;&nbsp;&nbsp;</span
    >
  </div>

  <div class="col-sm-3">
    <locumsnest-input-field
      [placeholder]="'First Name'"
      [ngrxFormControlState]="officer.controls.user.controls.firstName"
      [errorMsg]="getErrorMsg(officer.controls.user.controls.firstName)"
      [isInvalid]="
        officer.controls.user.controls.firstName.isDirty &&
        officer.controls.user.controls.firstName.isInvalid
      "
    >
    </locumsnest-input-field>
  </div>

  <div class="col-sm-3">
    <locumsnest-input-field
      [placeholder]="'Last Name'"
      [ngrxFormControlState]="officer.controls.user.controls.lastName"
      [errorMsg]="getErrorMsg(officer.controls.user.controls.lastName)"
      [isInvalid]="
        officer.controls.user.controls.lastName.isDirty &&
        officer.controls.user.controls.lastName.isInvalid
      "
    >
    </locumsnest-input-field>
  </div>

  <div class="col-sm-3">
    <locumsnest-input-field
      [placeholder]="'Email'"
      [ngrxFormControlState]="officer.controls.user.controls.email"
      [errorMsg]="getErrorMsg(officer.controls.user.controls.email)"
      [isInvalid]="
        officer.controls.user.controls.email.isDirty &&
        officer.controls.user.controls.email.isInvalid
      "
    >
    </locumsnest-input-field>
  </div>
  <div class="col-sm-3 permissions-dropdown">
    <locumsnest-dropdown-grouping-select
      [ngrxUpdateOn]="ngrxUpdateOnType.BLUR"
      [placeholder]="'Permissions'"
      [options]="groupOptions"
      [multiple]="true"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      [clearable]="true"
      [ngrxFormControlState]="officer.controls.user.controls.groups"
    ></locumsnest-dropdown-grouping-select>
    <div
      *ngIf="
        officer.controls.user.controls.groups.isDirty &&
        officer.controls.user.controls.groups.isInvalid
      "
    >
      <small
        *ngIf="getErrorMsg(officer.controls.user.controls.groups) as errorMsg"
        class="text-danger"
        >{{ errorMsg }}</small
      >
    </div>
  </div>
</div>
<div *ngIf="hasPermission" class="row additional-details">
  <div class="col-sm-4">
    <locumsnest-dropdown-grouping-select
      [ngrxUpdateOn]="ngrxUpdateOnType.BLUR"
      [placeholder]="'Professions'"
      [options]="professionOptions"
      [clearable]="true"
      [ngrxFormControlState]="officer.controls.professions"
      [multiple]="true"
    ></locumsnest-dropdown-grouping-select>
    <div *ngIf="officer.controls.professions.isDirty && officer.controls.professions.isInvalid">
      <small *ngIf="getErrorMsg(officer.controls.sites) as errorMsg" class="text-danger">{{
        errorMsg
      }}</small>
    </div>
  </div>
  <div class="col-sm-4">
    <locumsnest-dropdown-grouping-select
      [ngrxUpdateOn]="ngrxUpdateOnType.BLUR"
      [placeholder]="'Sites'"
      [options]="siteOptions"
      [multiple]="true"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      [clearable]="true"
      [ngrxFormControlState]="officer.controls.sites"
    ></locumsnest-dropdown-grouping-select>
    <div *ngIf="officer.controls.sites.isDirty && officer.controls.sites.isInvalid">
      <small *ngIf="getErrorMsg(officer.controls.sites) as errorMsg" class="text-danger">{{
        errorMsg
      }}</small>
    </div>
  </div>
  <div class="col-sm-4">
    <locumsnest-dropdown-grouping-select
      [ngrxUpdateOn]="ngrxUpdateOnType.BLUR"
      [placeholder]="'Departments'"
      [options]="specialtyOptions"
      [multiple]="true"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      [clearable]="true"
      [ngrxFormControlState]="officer.controls.departments"
    ></locumsnest-dropdown-grouping-select>
    <div *ngIf="officer.controls.departments.isDirty && officer.controls.departments.isInvalid">
      <small *ngIf="getErrorMsg(officer.controls.departments) as errorMsg" class="text-danger">{{
        errorMsg
      }}</small>
    </div>
  </div>
</div>
