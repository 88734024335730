<fieldset>
  <div *ngIf="passwordChangeForm?.controls?.oldPassword" class="row">
    <div class="col-sm-4 label">Old Password</div>
    <div class="col-sm-7">
      <div class="input-field">
        <locumsnest-input-field
          [idx]="idx + '__old-password'"
          [ngrxFormControlState]="passwordChangeForm?.controls?.oldPassword"
          [type]="'password'"
          [errorMsg]="getErrorMsg(passwordChangeForm?.controls.oldPassword)"
          [isInvalid]="
            passwordChangeForm?.controls.oldPassword.isDirty &&
            passwordChangeForm?.controls.oldPassword.isInvalid
          "
        ></locumsnest-input-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 label">New Password</div>
    <div class="col-sm-7">
      <div class="input-field">
        <locumsnest-input-field
          [idx]="idx + '__new-password-1'"
          [ngrxFormControlState]="passwordChangeForm?.controls?.newPassword1"
          [type]="'password'"
          [errorMsg]="getErrorMsg(passwordChangeForm?.controls.newPassword1)"
          [isInvalid]="
            passwordChangeForm?.controls.newPassword1.isDirty &&
            passwordChangeForm?.controls.newPassword1.isInvalid
          "
        ></locumsnest-input-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 label">New Password Confirmation</div>
    <div class="col-sm-7">
      <div class="input-field">
        <locumsnest-input-field
          [idx]="idx + '__new-password-2'"
          [ngrxFormControlState]="passwordChangeForm?.controls?.newPassword2"
          [type]="'password'"
          [errorMsg]="getErrorMsg(passwordChangeForm?.controls.newPassword2)"
          [isInvalid]="
            passwordChangeForm?.controls.newPassword2.isDirty &&
            passwordChangeForm?.controls.newPassword2.isInvalid
          "
        ></locumsnest-input-field>
      </div>
    </div>
  </div>
</fieldset>
