import { signalableFactory as actionableFactory, alertStateAdapter } from './form.adapter';

const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializeHospitalOfficerFormSignal extends actionableFactory.create<
  'Initialize Hospital Officer Form',
  Record<string, never>
>('Initialize Hospital Officer Form') {}

export class SubmitFormSignal extends actionableFactory.create<'Submit Form', void>(
  'Submit Form'
) {}

export class ResetAlertSignal extends ResetErrorSignal {}
