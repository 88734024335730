<fieldset>
  <div class="row">
    <div class="col-sm-4">Name</div>
    <div class="col-sm-7">{{ hospitalDetails?.name }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Payroll Liaison Officer</div>
    <div class="col-sm-7">{{ hospitalDetails?.payrollOfficer }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Locum's Nest Lead</div>
    <div class="col-sm-7">{{ hospitalDetails?.locumsNestLead }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Address</div>
    <div class="col-sm-7">
      <ng-container *ngIf="hospitalDetails?.address; else noAddress">
        <span *ngIf="hospitalDetails?.address?.addressLine1">
          {{ hospitalDetails?.address?.addressLine1 }}
        </span>
        <span *ngIf="hospitalDetails?.address?.addressLine2">
          <br />{{ hospitalDetails?.address?.addressLine2 }}
        </span>
        <br />{{ hospitalDetails?.address?.city?.name }}
        <span *ngIf="hospitalDetails?.address?.postcode">
          <br />{{ hospitalDetails?.address?.postcode }}
        </span>
        <span *ngIf="hospitalDetails?.address?.city.country.name">
          <br />{{ hospitalDetails?.address?.city.country.name }}
        </span>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">Telephone</div>
    <div class="col-sm-7">{{ hospitalDetails?.telephone }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Description</div>
    <div class="col-sm-7" [innerHtml]="hospitalDetails?.description"></div>
  </div>
</fieldset>

<ng-template #noAddress> - </ng-template>
