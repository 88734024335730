import { ActionReducerMap } from '@ngrx/store';

import * as fromForm from './form/form.reducer';
import * as fromEntity from './hospital-officer.reducer';
import { IHospitalOfficerFeatureState } from './interfaces';

export * from './hospital-officer.messages';
export const reducers: ActionReducerMap<IHospitalOfficerFeatureState> = {
  entityState: fromEntity.paginatedEntityStateReducer,
  entityLoadingState: fromEntity.loadingStateReducer,
  formState: fromForm.reducer,
};
