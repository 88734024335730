import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { AuthGroupService } from '../../../auth-group/+state/auth-group.service';
import { EmailFrequencyService } from '../../../email-frequency/+state/email-frequency.service';
import {
  IHospitalOfficerEntity,
  IHospitalOfficerUpdateEntity,
} from '../../../interfaces/api/hospital-officer-entity';
import { UpdateOneMessage } from '../hospital-officer.messages';
import { HospitalOfficerPersistenceService } from '../hospital-officer.persistence.service';
import { HospitalOfficerService } from '../hospital-officer.service';
import { alertHandler, alertSignalToMessage, conditionalErrorHandler } from './form.adapter';
import { InitializeHospitalOfficerFormMessage } from './form.messages';
import * as FormSelectors from './form.selectors';
import { InitializeHospitalOfficerFormSignal, SubmitFormSignal } from './form.signals';

@Injectable()
export class HospitalOfficerFormEffects {
  initializeHospitalOfficerFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeHospitalOfficerFormSignal>(InitializeHospitalOfficerFormSignal.TYPE),
      mergeMap(() =>
        merge(
          this.emailFrequencyService.load(),
          this.authGroupService.fetchMy(),
          this.hospitalOfficerService.loadCurrent(true, true)
        )
      )
    )
  );

  submitFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitFormSignal>(SubmitFormSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(FormSelectors.selectHospitalOfficerFormState))),
      mergeMap(([_, formState]) => {
        const hospitalOfficer = FormSelectors.getEntityStateFromFormState(formState);

        return this.hospitalOfficerPersistenceService
          .patch<IHospitalOfficerUpdateEntity>(hospitalOfficer.id, { ...hospitalOfficer })
          .pipe(
            mergeMap((response: IHospitalOfficerEntity) => {
              const hospitalOfficerFormState =
                FormSelectors.getHospitalOfficerFormStateFromEntity(response);
              return merge(
                of(new InitializeHospitalOfficerFormMessage({ hospitalOfficerFormState })),
                alertHandler(
                  { message: 'Hospital Officer updated successfully!', type: 'success' },
                  this.actions$
                ),
                of(
                  new UpdateOneMessage({
                    entity: {
                      id: response.id,
                      changes: response,
                    },
                  })
                )
              );
            }),
            catchError(conditionalErrorHandler())
          );
      }),
      filter((actions) => !!actions)
    )
  );

  alertSignal$ = createEffect(() => this.actions$.pipe(alertSignalToMessage));

  constructor(
    private actions$: Actions,
    private store: Store,
    private emailFrequencyService: EmailFrequencyService,
    private hospitalOfficerService: HospitalOfficerService,
    private hospitalOfficerPersistenceService: HospitalOfficerPersistenceService,
    private authGroupService: AuthGroupService
  ) {}
}
