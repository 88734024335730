import { Action } from '@ngrx/store';
import { IEmailFrequencyEntity } from '../../interfaces/api/email-frequency-entity';

export enum EmailFrequencySignalTypes {
  LOAD_EMAIL_FREQUENCY = '[EmailFrequency][Signal] Load Collection',
}
export enum EmailFrequencyMessageTypes {
  SET_COLLECTION = '[EmailFrequency][Message] Set Collection',
  ADD_ONE = '[EmailFrequency][Message] Add One',
  UPDATE_ONE = '[EmailFrequency][Message] Update One',
  UPSERT_ONE = '[EmailFrequency][Message] Upsert One',
  DELETE_ONE = '[EmailFrequency][Message] Delete One',
  ADD_MULTIPLE = '[EmailFrequency][Message] Add All',
  DELETE_MULTIPLE = '[EmailFrequency][Message] Delete Many',
  UPSERT_MULTIPLE = '[EmailFrequency][Message] Upsert Many',
  UPDATE_MULTIPLE = '[EmailFrequency][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = EmailFrequencyMessageTypes.SET_COLLECTION;
  constructor(public payload: { emailFrequencies: IEmailFrequencyEntity[] }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = EmailFrequencyMessageTypes.UPSERT_ONE;

  constructor(public payload: { emailFrequency: IEmailFrequencyEntity }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = EmailFrequencyMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { emailFrequencies: IEmailFrequencyEntity[] }) {}
}

export type EmailFrequencyMessages =
  | SetCollectionMessage
  | UpsertOneMessage
  | UpsertMultipleMessage;
