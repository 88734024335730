import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocumsNestComponent } from '@locumsnest/components/src/lib/core/locums-nest-component';
import { IHospitalDetailsEntity } from '../../../interfaces/api/hospital-entity';

@Component({
  selector: 'locumsnest-hospital-details',
  templateUrl: './hospital-details.component.html',
  styleUrls: ['./hospital-details.component.scss'],
})
export class HospitalDetailsComponent extends LocumsNestComponent {
  constructor(protected router: Router) {
    super();
  }

  @Input() hospitalDetails: IHospitalDetailsEntity;
}
