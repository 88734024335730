<fieldset>
  <div class="row">
    <div class="col-sm-4">Name</div>
    <div class="col-sm-7">{{ hospitalOfficerPersonalDetails?.firstName }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Surname</div>
    <div class="col-sm-7">{{ hospitalOfficerPersonalDetails?.lastName }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Job Title</div>
    <div class="col-sm-7">{{ hospitalOfficerPersonalDetails?.jobTitle }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Email</div>
    <div class="col-sm-7">
      <div class="input-field">
        <locumsnest-input-field
          [idx]="idx + '__email'"
          [ngrxFormControlState]="hospitalOfficerForm?.controls?.email"
          [errorMsg]="getErrorMsg(hospitalOfficerForm?.controls?.email)"
        ></locumsnest-input-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">Telephone</div>
    <div class="col-sm-7">
      <div class="input-field">
        <locumsnest-input-field
          [idx]="idx + '__telephone'"
          [ngrxFormControlState]="hospitalOfficerForm?.controls?.telephone"
          [errorMsg]="getErrorMsg(hospitalOfficerForm?.controls?.telephone)"
        ></locumsnest-input-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <locumsnest-section-title
        [title]="'Email Preferences'"
        [titleType]="'h2'"
      ></locumsnest-section-title>
    </div>
  </div>
  <ng-container
    *ngTemplateOutlet="
      digestFrequency;
      context: {
        title: 'activity',
        formControl: hospitalOfficerForm?.controls?.activityDigestEmailFrequency
      }
    "
  ></ng-container>
  <ng-container *ngIf="hasUnlockJobListingRatesPermission">
    <ng-container
      *ngTemplateOutlet="
        digestFrequency;
        context: {
          title: 'locked shifts',
          formControl: hospitalOfficerForm?.controls?.shiftsLockedDigestEmailFrequency
        }
      "
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="hasUnlockTimesheetRatesPermission">
    <ng-container
      *ngTemplateOutlet="
        digestFrequency;
        context: {
          title: 'locked timesheets',
          formControl: hospitalOfficerForm?.controls?.timeSheetsLockedDigestEmailFrequency
        }
      "
    ></ng-container
  ></ng-container>

  <div *ngIf="hasStaffbankManagerPermission" class="row">
    <div class="col-sm-4">
      <div class="row">
        <div class="col-sm-12">Receive Staff Bank Rundown Email</div>
      </div>
    </div>
    <div class="col-sm-7 rundown-email">
      <locumsnest-check-box
        [idx]="idx + '__receive-staffbank-rundown-email'"
        [theme]="'blue'"
        [size]="'normal'"
        [ngrxFormControlState]="hospitalOfficerForm?.controls?.receiveStaffbankRundownEmail"
      >
      </locumsnest-check-box>
    </div>
  </div>
</fieldset>

<ng-template #digestFrequency let-title="title" let-formControl="formControl">
  <div class="row">
    <div class="col-sm-4">
      <div class="row">
        <div class="col-sm-12">{{ title | titlecase }} Digest Frequency</div>
        <div class="col-sm-12 help-text">
          Configure how often you want to receive {{ title }} digest emails
        </div>
      </div>
    </div>
    <div class="col-sm-7">
      <locumsnest-dropdown-grouping-select
        placeholder="Select {{ title }} digest frequency"
        [idx]="idx + '__' + (title | kebabcase) + '-digest-email-frequency'"
        [options]="emailFrequencies"
        [isDisabled]="!emailFrequencies"
        [ngrxFormControlState]="formControl"
      ></locumsnest-dropdown-grouping-select>
    </div>
  </div>
</ng-template>
