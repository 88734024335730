import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AccountModule } from '@locumsnest/auth/src/account';
import { ComponentsModule } from '@locumsnest/components';
import { PipesModule } from '@locumsnest/components/src/lib/pipes/pipes.module';

import { PasswordChangeComponent } from '../account/components/password-change/password-change.component';
import { EmailFrequencyModule } from '../email-frequency/email-frequency.module';
import { HospitalDetailsComponent } from './../hospital/components/hospital-details/hospital-details.component';
import { UserModule } from './../user/user.module';
import { reducers } from './+state';
import { HospitalOfficerFormEffects } from './+state/form';
import { featureKey } from './+state/hospital-officer.reducer';
import { HospitalOfficerConfigurationListItemComponent } from './components/hospital-officer-configuration-list-item/hospital-officer-configuration-list-item.component';
import { HospitalOfficerConfigurationListComponent } from './components/hospital-officer-configuration-list/hospital-officer-configuration-list.component';
import { HospitalOfficerPersonalDetailsComponent } from './components/hospital-officer-personal-details/hospital-officer-personal-details.component';
import { HospitalOfficerPageComponent } from './containers/hospital-officer-page/hospital-officer-page.component';
import { HospitalOfficerRoutingModule } from './hospital-officer.routing.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    NgrxFormsModule,
    HospitalOfficerRoutingModule,
    UserModule,
    InfiniteScrollModule,
    EmailFrequencyModule,
    PipesModule,
    PasswordChangeComponent,
    StoreModule.forFeature(featureKey, reducers),
    AccountModule,
    EffectsModule.forFeature([HospitalOfficerFormEffects]),
  ],
  declarations: [
    HospitalOfficerPageComponent,
    HospitalOfficerPersonalDetailsComponent,
    HospitalDetailsComponent,
    HospitalOfficerConfigurationListItemComponent,
    HospitalOfficerConfigurationListComponent,
  ],
  exports: [
    HospitalOfficerConfigurationListItemComponent,
    HospitalOfficerConfigurationListComponent,
  ],
})
export class HospitalOfficerModule {}
