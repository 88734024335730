import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { IEmailFrequencyEntity } from '../../interfaces/api/email-frequency-entity';
import { selectAll } from './email-frequency.reducer';
import {
  IEmailFrequencyEntityState,
  IEmailFrequencyFeatureState,
  IEmailFrequencySharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IEmailFrequencyEntityState }) =>
  state.entityState;

export const getEmailFrequencyOptions = (
  emailFrequencies: IEmailFrequencyEntity[]
): ISelectOption[] =>
  emailFrequencies
    .map((emailFrequency) => ({
      id: emailFrequency.val,
      name: emailFrequency.display,
      disabled: emailFrequency.code === 'OTHER',
    }))
    .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));

export const getEmailFrequency =
  (emailFrequencyId: number) => (emailFrequencies: IEmailFrequencyEntity[]) => {
    const emailFrequency = emailFrequencies.find((frequency) => frequency.val === emailFrequencyId);

    if (emailFrequency) {
      return emailFrequency.display;
    }
    return '';
  };

export const getEmailFrequencyCodeById =
  (emailFrequencyId: number) => (emailFrequencies: IEmailFrequencyEntity[]) => {
    const emailFrequency = emailFrequencies.find((frequency) => frequency.val === emailFrequencyId);

    if (emailFrequency) {
      return emailFrequency.code;
    }
    return '';
  };

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectEmailFrequencyState = createFeatureSelector<
  IEmailFrequencyFeatureState & IEmailFrequencySharedState
>('emailFrequencies');
export const selectEmailFrequencyEntityState = createSelector(
  selectEmailFrequencyState,
  getEntityState
);

export const selectAllEmailFrequencies = createSelector(selectEmailFrequencyEntityState, selectAll);

export const selectEmailFrequencyOptions = createSelector(
  selectAllEmailFrequencies,
  getEmailFrequencyOptions
);

export const selectEmailFrequency = (emailFrequencyId) =>
  createSelector(selectAllEmailFrequencies, getEmailFrequency(emailFrequencyId));

export const selectEmailFrequencyCodeById = (emailFrequencyId) =>
  createSelector(selectAllEmailFrequencies, getEmailFrequencyCodeById(emailFrequencyId));
