import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroupState, NGRX_UPDATE_ON_TYPE } from 'ngrx-forms';

import { ISelectGroupOption, ISelectOption } from '@locumsnest/components';
import { LocumsNestComponent } from '@locumsnest/components/src/lib/core/locums-nest-component';

import { IHospitalOfficerConfigurationFormState } from '../../+state/interfaces';

@Component({
  selector: 'locumsnest-hospital-officer-configuration-list-item',
  templateUrl: './hospital-officer-configuration-list-item.component.html',
  styleUrls: ['./hospital-officer-configuration-list-item.component.scss'],
})
export class HospitalOfficerConfigurationListItemComponent extends LocumsNestComponent {
  @Input() officer: FormGroupState<IHospitalOfficerConfigurationFormState>;
  @Input() specialtyOptions: ISelectGroupOption;
  @Input() professionOptions: ISelectOption;
  @Input() siteOptions: ISelectOption;
  @Input() groupOptions: ISelectOption;
  @Input() isRemovable = true;
  @Input() hasPermission = true;
  @Output() remove = new EventEmitter<number>();

  public ngrxUpdateOnType = NGRX_UPDATE_ON_TYPE;

  constructor(protected router: Router) {
    super();
  }

  onRemove(id: number): void {
    if (this.isRemovable) {
      this.remove.emit(id);
    }
  }
}
