import {
  EmailFrequencyMessageTypes as MessageTypes,
  EmailFrequencyMessages,
} from './email-frequency.messages';
import { IEmailFrequencyEntityState } from './interfaces';
import { adapter, initialState } from './email-frequency.adapter';
import { combineReducers } from '@ngrx/store';

export * from './interfaces';

export function entityReducer(
  state = initialState,
  action: EmailFrequencyMessages
): IEmailFrequencyEntityState {
  switch (action.type) {
    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.emailFrequency, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.emailFrequencies, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.emailFrequencies, state);
    }

    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function reducer(state, action) {
  return combineReducers({
    entityState: entityReducer,
  })(state, action);
}
