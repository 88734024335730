import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroupState, NgrxFormsModule } from 'ngrx-forms';

import { IPasswordChangeFormState } from '@locumsnest/auth/src/account/+state/password-change-form';
import { AtomsModule } from '@locumsnest/components/src';
import { LocumsNestComponent } from '@locumsnest/components/src/lib/core/locums-nest-component';

@Component({
  selector: 'locumsnest-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  standalone: true,
  imports: [AtomsModule, NgIf, NgrxFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordChangeComponent extends LocumsNestComponent {
  @Input() idx: string;
  @Input() passwordChangeForm: FormGroupState<IPasswordChangeFormState>;
}
