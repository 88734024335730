import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  IPasswordChangeFormState,
  selectPasswordChangeAlertToDisplay,
  selectPasswordChangeFormState,
} from '@locumsnest/auth/src/account/+state/password-change-form';
import {
  ResetAlertSignal as PasswordChangeResetAlertSignal,
  SubmitPasswordChangeFormSignal,
} from '@locumsnest/auth/src/account/+state/password-change-form/password-change-form.signals';
import { ISelectOption } from '@locumsnest/components/src';
import { LocumsNestComponent } from '@locumsnest/components/src/lib/core/locums-nest-component';
import { IAlertState } from '@locumsnest/core/src';
import { getScrollDetails } from '@locumsnest/core/src/lib/helpers/pageScroll';

import * as fromForm from '../../+state/form/';
import {
  ResetAlertSignal as HospitalDetailsResetAlertSignal,
  InitializeHospitalOfficerFormSignal,
  SubmitFormSignal,
} from '../../+state/form/form.signals';
import { selectHospitalOfficerPersonalDetails } from '../../+state/hospital-officer.selectors';
import { IHospitalOfficerFormState } from '../../+state/interfaces/hospital-officer-form-state';
import { AuthGroupService } from '../../../auth-group/+state/auth-group.service';
import { AUTH_GROUPS } from '../../../auth-group/constants';
import { APP_DETAILS_TOKEN } from '../../../core/opaque-tokens';
import { EmailFrequencyService } from '../../../email-frequency/+state/email-frequency.service';
import { selectHospitalDetails } from '../../../hospital/+state/hospital.selectors';
import { IHospitalDetailsEntity } from '../../../interfaces/api/hospital-entity';
import { IHospitalOfficerPersonalDetailsEntity } from '../../../interfaces/api/hospital-officer-entity';
import { PermissionService } from './../../../permission/+state/permission.service';

const selector = 'locumsnest-hospital-officer-page';

@Component({
  selector,
  templateUrl: './hospital-officer-page.component.html',
  styleUrls: ['./hospital-officer-page.component.scss'],
})
export class HospitalOfficerPageComponent extends LocumsNestComponent implements OnInit {
  public readonly idPageName = selector;
  public hospitalOfficerForm$: Observable<FormGroupState<IHospitalOfficerFormState>>;
  public hospitalDetailsAlertToDisplay$: Observable<IAlertState>;
  public passwordChangeAlertToDisplay$: Observable<IAlertState>;

  public scrollDetails$;
  public passwordUpdatedSuccessFully$: Observable<boolean>;
  public hospitalOfficerPersonalDetails$: Observable<IHospitalOfficerPersonalDetailsEntity>;
  public hospitalDetails$: Observable<IHospitalDetailsEntity>;
  public passwordChangeForm$: Observable<FormGroupState<IPasswordChangeFormState>>;
  public emailFrequencies$: Observable<ISelectOption[]>;
  public hasStaffbankManagerPermission$: Observable<boolean>;
  public hasUnlockJobListingRatesPermission$: Observable<boolean>;
  public hasUnlockTimesheetRatesPermission$: Observable<boolean>;

  get version() {
    return this.packageDetails.version;
  }

  constructor(
    private store: Store,
    protected router: Router,
    private emailFrequencyService: EmailFrequencyService,
    private authGroupService: AuthGroupService,
    private permissionService: PermissionService,
    @Inject(APP_DETAILS_TOKEN) public packageDetails: { version: string }
  ) {
    super();
    this.hospitalOfficerForm$ = this.store.pipe(select(fromForm.selectHospitalOfficerFormState));
    this.passwordChangeForm$ = this.store.pipe(select(selectPasswordChangeFormState));
    this.emailFrequencies$ = this.emailFrequencyService.getEmailFrequencyOptions();
    this.hasStaffbankManagerPermission$ = this.authGroupService.getOfficerBelongsToAuthGroup(
      AUTH_GROUPS.STAFFBANK_MANAGER
    );
    this.hasUnlockJobListingRatesPermission$ = this.permissionService.hasCurrentPermission(
      'unlock_job_listing_rates'
    );
    this.hasUnlockTimesheetRatesPermission$ =
      this.permissionService.hasCurrentPermission('unlock_time_sheet_rates');

    this.hospitalDetailsAlertToDisplay$ = this.store.pipe(
      select(fromForm.selectHospitalOfficerAlertToDisplay)
    );
    this.passwordChangeAlertToDisplay$ = this.store.pipe(
      select(selectPasswordChangeAlertToDisplay)
    );

    this.hospitalDetails$ = this.store.pipe(select(selectHospitalDetails));

    this.passwordUpdatedSuccessFully$ = this.store.pipe(
      select(selectPasswordChangeFormState),
      map(
        (passwordChangeFormState) =>
          passwordChangeFormState.controls.passwordSuccessFullyUpdated.value
      ),
      filter((flag) => flag)
    );

    this.hospitalOfficerPersonalDetails$ = this.store.pipe(
      select(selectHospitalOfficerPersonalDetails)
    );
  }

  public onSaveHospitalOfficerForm() {
    this.store.dispatch(new SubmitFormSignal());
  }

  public onPasswordChangeForm() {
    this.store.dispatch(new SubmitPasswordChangeFormSignal());
  }

  public onPasswordUpdateAlertClose() {
    this.router.navigateByUrl('/login');
  }

  public onHospitalDetailsAlertToastClicked() {
    this.store.dispatch(new HospitalDetailsResetAlertSignal({}));
  }

  public onPasswordChangeAlertToastClicked() {
    this.store.dispatch(new PasswordChangeResetAlertSignal({}));
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.store.dispatch(new InitializeHospitalOfficerFormSignal({}));
    this.scrollDetails$ = getScrollDetails();
  }
}
