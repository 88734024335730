import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectGroupOption, ISelectOption } from '@locumsnest/components';
import { FormArrayState } from 'ngrx-forms';
import { IHospitalOfficerConfigurationFormState } from '../../+state/interfaces';
@Component({
  selector: 'locumsnest-hospital-officer-configuration-list',
  templateUrl: './hospital-officer-configuration-list.component.html',
  styleUrls: ['./hospital-officer-configuration-list.component.scss'],
})
export class HospitalOfficerConfigurationListComponent {
  @Input() officers: FormArrayState<IHospitalOfficerConfigurationFormState>;
  @Input() specialtyOptions: ISelectGroupOption;
  @Input() professionOptions: ISelectOption;
  @Input() siteOptions: ISelectOption;
  @Input() groupOptions: ISelectOption;
  @Output() remove = new EventEmitter<number>();
  @Output() add = new EventEmitter<void>();

  constructor() {}

  onAdd(): void {
    this.add.emit();
  }

  onRemove(index: number): void {
    this.remove.emit(index);
  }

  trackByFn(index: number): number {
    return index;
  }
}
