import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';

import { ISelectOption } from '@locumsnest/components/src';
import { LocumsNestComponent } from '@locumsnest/components/src/lib/core/locums-nest-component';

import { IHospitalOfficerFormState } from '../../+state/form';
import { IHospitalOfficerPersonalDetailsEntity } from '../../../interfaces/api/hospital-officer-entity';

@Component({
  selector: 'locumsnest-hospital-officer-personal-details',
  templateUrl: './hospital-officer-personal-details.component.html',
  styleUrls: ['./hospital-officer-personal-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HospitalOfficerPersonalDetailsComponent extends LocumsNestComponent {
  @Input() idx: string;
  @Input() hospitalOfficerPersonalDetails: IHospitalOfficerPersonalDetailsEntity;
  @Input() hospitalOfficerForm: FormGroupState<IHospitalOfficerFormState>;
  @Input() emailFrequencies: ISelectOption[];
  @Input() hasStaffbankManagerPermission: boolean;
  @Input() hasUnlockJobListingRatesPermission: boolean;
  @Input() hasUnlockTimesheetRatesPermission: boolean;
}
