import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { AttributeStateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { IEmailFrequencyEntity } from '../../interfaces/api/email-frequency-entity';
import { SetCollectionMessage } from './email-frequency.messages';
import { EmailFrequencyPersistenceService } from './email-frequency.persistence.service';
import {
  selectAllEmailFrequencies,
  selectEmailFrequency,
  selectEmailFrequencyCodeById,
  selectEmailFrequencyOptions,
} from './email-frequency.selectors';

@Injectable({
  providedIn: 'root',
})
export class EmailFrequencyService extends AttributeStateService<IEmailFrequencyEntity> {
  constructor(
    private store: Store,
    protected persistenceService: EmailFrequencyPersistenceService
  ) {
    super();
  }
  getAll() {
    return this.store.pipe(select(selectAllEmailFrequencies), filterNonEmpty);
  }
  getEmailFrequencyOptions() {
    return this.store.pipe(
      select(selectEmailFrequencyOptions),
      filter((options) => options && options.length > 0)
    );
  }
  getOne(id: number) {
    return this.store.pipe(select(selectEmailFrequency(id)));
  }

  getEmailFrequencyCodeById(id: number) {
    return this.store.pipe(select(selectEmailFrequencyCodeById(id)));
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map((emailFrequencies) => new SetCollectionMessage({ emailFrequencies })));
  }
}
