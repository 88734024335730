<div
  class="row"
  *ngFor="let officer of officers.controls; let i = index; trackBy: trackByFn"
  style="margin-bottom: 20px"
>
  <div class="col-sm-10">
    <locumsnest-hospital-officer-configuration-list-item
      [officer]="officer"
      [specialtyOptions]="specialtyOptions"
      [professionOptions]="professionOptions"
      [siteOptions]="siteOptions"
      [groupOptions]="groupOptions"
      [isRemovable]="i > 0"
      (remove)="onRemove(i)"
    >
    </locumsnest-hospital-officer-configuration-list-item>
  </div>
  <div class="col-sm-2">
    <locumsnest-action-button
      *ngIf="i === officers.controls.length - 1"
      [buttonType]="'button'"
      (click)="onAdd()"
    >
      <span class="button-text"> + Add </span>
    </locumsnest-action-button>
  </div>
</div>
