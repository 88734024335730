import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@locumsnest/components/src';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';
import { EmailFrequencyEffects, reducers } from './+state';
import { EmailFrequencyRoutingModule } from './email-frequency.routing.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    NgrxFormsModule,
    EmailFrequencyRoutingModule,
    /**
     * StoreModule.forFeature is used for composing state
     * from feature modules. These modules can be loaded
     * eagerly or lazily and will be dynamically added to
     * the existing state.
     */
    StoreModule.forFeature('emailFrequencies', reducers),

    /**
     * Effects.forFeature is used to register effects
     * from feature modules. Effects can be loaded
     * eagerly or lazily and will be started immediately.
     *
     * All Effects will only be instantiated once regardless of
     * whether they are registered once or multiple times.
     */
    EffectsModule.forFeature([EmailFrequencyEffects]),
  ],
  declarations: [],
})
export class EmailFrequencyModule {}
