import { createFormGroupState, createFormStateReducerWithUpdate, FormGroupState } from 'ngrx-forms';

import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';

import { IHospitalOfficerFormState } from '../interfaces/hospital-officer-form-state';
import { alertStateAdapter } from './form.adapter';
import { HospitalOfficerFormMessages, InitializeHospitalOfficerFormMessage } from './form.messages';

export * from './form.selectors';
export const FORM_ID = 'HOSPITAL_OFFICER_FORM';
export type State = FormGroupState<IHospitalOfficerFormState> & { alertState?: IAlertState };

export const INITIAL_FORM_STATE = createFormGroupState<IHospitalOfficerFormState>(FORM_ID, {
  id: null,
  email: null,
  telephone: null,
  activityDigestEmailFrequency: null,
  receiveStaffbankRundownEmail: false,
  shiftsLockedDigestEmailFrequency: null,
  timeSheetsLockedDigestEmailFrequency: null,
  alertState: {
    message: '',
    type: '',
    displayAlertMessage: false,
  },
});

export const formStateReducer = createFormStateReducerWithUpdate<IHospitalOfficerFormState>([]);

const alertStateReducer = alertStateAdapter.createReducer({});

export function formReducer(
  state: FormGroupState<IHospitalOfficerFormState> = INITIAL_FORM_STATE,
  action: HospitalOfficerFormMessages
) {
  switch (action.type) {
    case InitializeHospitalOfficerFormMessage.TYPE:
      state = createFormGroupState<IHospitalOfficerFormState>(
        FORM_ID,
        (action as InitializeHospitalOfficerFormMessage).payload.hospitalOfficerFormState
      );
      break;
  }

  state = formStateReducer(state, action);

  return state;
}

export function reducer(state: State, action) {
  const form = formReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);
  return {
    ...form,
    alertState,
  };
}

export { IHospitalOfficerFormState } from '../interfaces/hospital-officer-form-state';
