import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IEmailFrequencyEntity } from '../../interfaces/api/email-frequency-entity';

export function sortByName(a: IEmailFrequencyEntity, b: IEmailFrequencyEntity): number {
  return a.display.localeCompare(b.display);
}

export const adapter: EntityAdapter<IEmailFrequencyEntity> =
  createEntityAdapter<IEmailFrequencyEntity>({
    sortComparer: sortByName,
    selectId: (EmailFrequency: IEmailFrequencyEntity) => EmailFrequency.val,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedEmailFrequencyId: null,
});
