<div *ngIf="hospitalDetailsAlertToDisplay$ | async as alert">
  <locumsnest-toast
    [alertType]="alert.type"
    [message]="alert.message"
    [displayAlert]="alert.displayAlertMessage"
    (toastClicked)="onHospitalDetailsAlertToastClicked()"
  >
  </locumsnest-toast>
</div>

<div *ngIf="passwordChangeAlertToDisplay$ | async as alert">
  <locumsnest-toast
    [alertType]="alert?.type"
    [message]="alert?.message"
    [displayAlert]="alert?.displayAlertMessage"
    (toastClicked)="onPasswordChangeAlertToastClicked()"
  >
  </locumsnest-toast>
</div>

<form class="hospital-officer-form" [ngrxFormState]="hospitalOfficerForm$ | async">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="line"></div>
      </div>
    </div>
    <div class="row form-container">
      <div class="col-sm-8 main-form">
        <div class="row">
          <div class="col-sm-8">
            <locumsnest-section-title
              [title]="'My Account'"
              [titleType]="'h2'"
            ></locumsnest-section-title>
          </div>
          <div class="col-sm-4">
            <div class="button-wrapper">
              <locumsnest-button
                [idx]="idPageName + '__save-top'"
                [buttonType]="
                  (hospitalOfficerForm$ | async).userDefinedProperties.isAlert ? 'alert' : 'accept'
                "
                [disabled]="!(hospitalOfficerForm$ | async).isDirty"
                [size]="'small'"
                (click)="onSaveHospitalOfficerForm()"
              >
                Save Changes
              </locumsnest-button>
            </div>
          </div>
        </div>
        <locumsnest-hospital-officer-personal-details
          [idx]="idPageName + '__personal-details'"
          [hospitalOfficerPersonalDetails]="hospitalOfficerPersonalDetails$ | async"
          [hospitalOfficerForm]="hospitalOfficerForm$ | async"
          [emailFrequencies]="emailFrequencies$ | async"
          [hasStaffbankManagerPermission]="hasStaffbankManagerPermission$ | async"
          [hasUnlockJobListingRatesPermission]="hasUnlockJobListingRatesPermission$ | async"
          [hasUnlockTimesheetRatesPermission]="hasUnlockTimesheetRatesPermission$ | async"
        >
        </locumsnest-hospital-officer-personal-details>
        <div class="row">
          <div class="col-sm-8">
            <locumsnest-section-title
              [title]="'Your organisation’s details'"
              [titleType]="'h2'"
            ></locumsnest-section-title>
          </div>
        </div>
        <locumsnest-hospital-details [hospitalDetails]="hospitalDetails$ | async">
        </locumsnest-hospital-details>
        <div class="row">
          <div class="col-sm-8"></div>
          <div class="col-sm-4">
            <div class="button-wrapper">
              <locumsnest-button
                [idx]="idPageName + '__save-bottom'"
                [buttonType]="
                  (hospitalOfficerForm$ | async).userDefinedProperties.isAlert ? 'alert' : 'accept'
                "
                [disabled]="!(hospitalOfficerForm$ | async).isDirty"
                [size]="'small'"
                (click)="onSaveHospitalOfficerForm()"
              >
                Save Changes
              </locumsnest-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <locumsnest-section-title
              [title]="'Change Password'"
              [titleType]="'h2'"
            ></locumsnest-section-title>
          </div>
        </div>
        <locumsnest-password-change
          [idx]="idPageName + '__password-change'"
          [passwordChangeForm]="passwordChangeForm$ | async"
        >
        </locumsnest-password-change>
        <div class="row">
          <div class="col-sm-8"></div>
          <div class="col-sm-4">
            <div class="button-wrapper">
              <locumsnest-button
                [idx]="idPageName + '__change-password'"
                [buttonType]="
                  (passwordChangeForm$ | async).userDefinedProperties.isAlert ? 'alert' : 'info'
                "
                [disabled]="(passwordChangeForm$ | async).isInvalid"
                [size]="'small'"
                (click)="onPasswordChangeForm()"
              >
                Change Password
              </locumsnest-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="version">
  {{ version }}
</div>
<locumsnest-dialog
  [show]="passwordUpdatedSuccessFully$ | async"
  [title]="'Password Changed'"
  (close)="onPasswordUpdateAlertClose()"
>
  <ng-container body>
    <p>Your password has been updated successfully. Please login again with your new password</p>
  </ng-container>
  <ng-container footer #footer>
    <locumsnest-button
      [idx]="idPageName + '__ok'"
      [buttonType]="'info'"
      [size]="'small'"
      (click)="onPasswordUpdateAlertClose()"
    >
      Ok
    </locumsnest-button>
  </ng-container>
</locumsnest-dialog>
